import * as React from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
// import PlayVideo from "../../Play/PlayVideo";
import articleimg from "../../../images/advice/property/image.png"
import articletab from "../../../images/advice/property/tab-img.png"
import articledesk from "../../../images/advice/property/desk-img.png"

import NewsletterForm from "@Components/forms/newsletter-form";
import ReactMarkdown from "react-markdown"
import articlemob01 from "../../../images/advice/property/mob01.png"
import articletab01 from "../../../images/advice/property/tab01.png"
import articledesk01 from "../../../images/advice/property/desk01.png"


import articlemob02 from "../../../images/advice/property/mob02.png"
import articletab02 from "../../../images/advice/property/tab02.png"
import articledesk02 from "../../../images/advice/property/desk02.png"
import ScrollAnimation from 'react-animate-on-scroll';

import SocialShare from "@Components/Share/SocialShare";
import moment from "moment"
import { BLOGS_PAGE_URL, ADVICE_PAGE_URL } from "@Components/common/site/constants";

import "./BlogSection.scss"
import { Link } from "gatsby";
const BlogSection = (props) => {
    // console.log(props.page)
    return (
        props.isPage ?
            <section className="blog-section page">
                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {/* {JSON.stringify(props.page)} */}
                                <div className="blog-content">
                                    <h1>{props?.Title}</h1>
                                    <ReactMarkdown source={props?.page?.Content} allowDangerousHtml />
                                </div>
                            </Col>
                            
                        </Row>

                    </Container>
                </ScrollAnimation>

            </section>

            : <section className="blog-section notpage">
            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                    <Row>
                        <Col xs={12}>
                            {/* {JSON.stringify(props.page)} */}
                            <div className="blog-content">
                                <span className="sm-content">
                                    <Link to={'/'}>ALLSOPP & ALLSOPP RECRUITMENT</Link> / <Link to={ADVICE_PAGE_URL.alias}>advice</Link> / <Link to={BLOGS_PAGE_URL.alias}>blog</Link> /
                                                    </span>
                                <h1>{props.page?.Title}</h1>
                                <div className="about-content">
                                    <div className="icon-social icons">
                                        {/* <Link href="#"> */}
                                            <i className="icon-calendar">
                                            </i>
                                        {/* </Link> */}
                                        <span className="sm-text">
                                            {moment(props.page?.Date).format('DD MMMM YYYY')}
                                        </span>
                                    </div>
                                    <div className="icon-social d-none d-lg-flex">
                                        {/* <Link href="#"> */}
                                            <i className="icon-candidate">
                                            </i>
                                        {/* </Link> */}
                                        <span className="sm-text">
                                            {props.page?.Category}
                                        </span>
                                    </div>
                                    <SocialShare Title="Share" />

                                </div>
                                {props.page?.Modules && props.page?.Modules?.length > 0 ? props.page?.Modules.map((Module, i) => {
                                    return (
                                        <div key={i}>
                                            {Module?.Add_Blocks.map((item, j) => {
                                                return (
                                                    <div key={j}>
                                                        {item.Content &&
                                                            <ReactMarkdown source={item?.Content} allowDangerousHtml />
                                                        }

                                                        {item.Image?.length === 1 &&
                                                            <div className="blog-img">
                                                                <picture>
                                                                    <source media="(min-width:992px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.Add_BlocksalternativeText} />
                                                                    <source media="(min-width:768px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                    <img src={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                </picture>
                                                            </div>}
                                                        {item.Image?.length === 2 &&
                                                            <div className="img-group">
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className="img-zoom article-img">
                                                                            <picture>
                                                                                <source media="(min-width:992px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.Add_BlocksalternativeText} />
                                                                                <source media="(min-width:768px)" srcSet={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                                <img src={item.Image[0]?.url} alt={item.Image[0]?.alternativeText} />
                                                                            </picture>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className="img-zoom article-img">
                                                                            <picture>
                                                                                <source media="(min-width:992px)" srcSet={item.Image[1]?.url} alt={item.Image[1]?.Add_BlocksalternativeText} />
                                                                                <source media="(min-width:768px)" srcSet={item.Image[1]?.url} alt={item.Image[1]?.alternativeText} />
                                                                                <img src={item.Image[1]?.url} alt={item.Image[1]?.alternativeText} />
                                                                            </picture>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            })}

                                        </div>

                                    )
                                }) : ''}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="subscribe">

                                <span className="sm-text">
                                    <Link className="d-none d-lg-flex">
                                        <i className="icon-contact"></i>
                                    </Link>
                        <span className="up-to-date">Stay up to date</span>
                          </span>
                          <div className="blog-detail-news-form">
                                <NewsletterForm />
                                </div>
                            </div>
                        </Col>
                    </Row>

            </ScrollAnimation>

        </section>)
}
export default BlogSection